/* You can add global styles to this file, and also import other style files */
@import "assets/css/fonts.css";
@import "assets/css/helpers.css";
@import "assets/css/main.css";
@import "assets/css/mediaqueries.css";
@import "assets/css/buttons.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
/*@import url("https://use.fontawesome.com/releases/v5.13.0/css/all.css");*/

/*input, select, textarea{
    -webkit-user-select: auto !important;
}*/

.TitilliumWeb-Regular {
  font-family: TitilliumWeb-Regular !important;
}

.TitilliumWeb-SemiBold {
  font-family: TitilliumWeb-SemiBold!important;
}



.NgxEditor__Content {
  height: 200px!important;
  overflow: auto!important;
}
.drop-zone-class {
  height: 300px;
  border: 2px dashed grey;
  background-color: #f7f7f9;
  color:cornflowerblue;
  margin-bottom: -41px
}
.tags-input__tag {
  padding-right: 1em!important;
}

.badge {
  padding: 15px 17px!important;
  font-size: 14px;
}


#search{
    background : white;
    border : 1px solid #bcbcbc
}
agm-map {
  height: 300px;
}
#discover-events .btn, #upcoming-events .btn{
    margin-top : -20px;
}

.ngx-picker > button{
    display: none;
}
.ngx-picker input{
    display: block !important;
    height : 41px;
    width: 100% !important;
    padding: .5rem .75rem !important;
    font-size: 1rem !important;
    line-height: 1.25 !important;
    color: #464a4c !important;
    background-color: #fff !important;
    background-image: none !important;
    background-clip: padding-box !important;
    border: 1px solid rgba(0,0,0,.15) !important;
    border-radius: .25rem !important;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s !important;
}
.group > div:last-child{
    margin-bottom : 0px !important;
}
.fr-element.fr-view{
    min-height : 300px !important;
}

.datatable-header{
  color: #FFF;
  background: #1f0e3e;
}

.datatable-header-inner{
  display: flex;
  align-items: center;
  line-height: 50px;
  height: 50px !important;
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner{
  display: flex;
  align-items: center !important;
}

.datatable-header-cell{
  text-align: center;
}

.datatable-header-cell-label{
  padding-left: 10px;
}

.ngx-datatable .datatable-body-cell{
  text-align: center !important;
  border-right: 1px solid #e5e5e5;
}

.datatable-row-wrapper:nth-child(even){
  background: #e5e5e5;
}

.app-title{
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCC;
}

.app-title-custum{
  text-transform: uppercase;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCC;
}

.app-title-center{
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid #CCC;
  text-align: center;
}


.btn-action{
  width: 32px;
  height: 32px;
  padding: 6px;
  background: none;
  cursor: pointer;
}


@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {

  .ngx-datatable.responsive-table, .ngx-datatable .datatable-header-cell{

  }


  .ngx-datatable.responsive-table, .ngx-datatable.responsive-table .datatable-header, .ngx-datatable.responsive-table tbody,
  .ngx-datatable.responsive-table .datatable-header-cell, .ngx-datatable.responsive-table td,
  .ngx-datatable.responsive-table tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .ngx-datatable.responsive-table .datatable-header {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .ngx-datatable.responsive-table .datatable-row-wrapper{
    margin-bottom: 15px;
  }
  .ngx-datatable.responsive-table .datatable-row-center,
  .ngx-datatable.responsive-table .datatable-row-group {
    display: block;
    border: 1px solid #ccc;
  }

  .ngx-datatable.responsive-table .datatable-body .datatable-body-row > div{
    display: block;
  }

  .ngx-datatable.responsive-table .datatable-body-cell {
    border: none;
    overflow: unset !important;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 45%;
    width: 100% !important;
  }

  .ngx-datatable.responsive-table .datatable-body .datatable-scroll,
  .ngx-datatable.responsive-table .datatable-body .datatable-scroll,
  .ngx-datatable.responsive-table .datatable-body .datatable-body-row{
    width: 100% !important;
  }

  .ngx-datatable.responsive-table .datatable-body-cell:before {
    /* Now like a table header */
    position: absolute;
    content: attr(data-label)" ";
    /* Top/left values mimic padding */
    top: 0;
    left: 0;
    bottom: 0;
    width: 45%;
    padding: 5px;
    white-space: nowrap;
    color: white;
    background: #1f0e3e;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

}
.form-icon {
  height: 70px;
  margin-right: 20px;
  width: 75px;
}

.input-block-title {
  width: 85%;
  display: inline-block;
  font-weight: bolder;
  font-size: 31px;
  border-bottom: 5px solid var(--btn-bgColor);
}
.input-block-title-without-underline {
  width: 85%;
  display: inline-block;
  font-weight: bolder;
  font-size: 31px;
}

.img-preview {
  max-width: 100%;
}

.custom-alert-danger {
  background: transparent;
  border: none;
  padding-top: 0;
  padding-left: 0;
  font-size: .8em;
  font-style: italic;
  color: #E53935;
}

.bo-form-group:not(has-danger) .form-control-feedback {
  display: none;
}

.m-l-r-auto {
  margin-left: auto;
  margin-right: auto;
}

.block-body {
  padding: 5px 20px 8px 95px;
}
.label-big {
  font-size: 18px;
  font-weight: bolder;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.btn-custom {
  padding: 6px 30px;
  text-transform: uppercase;
  border: 3px solid var(--btn-bgColor)!important;
  background-color: white;
  color: var(--btn-bgColor);
  font-weight: bolder;
}
.selected-btn {
  background-color: azure!important;
}
.common-bg-color {
  background-color: var(--btn-bgColor);
}

.btn-custom-bis {
  padding: 6px 0px;
  text-transform: uppercase;
  border: 3px solid var(--btn-bgColor)!important;
  background-color: white;
  color: var(--btn-bgColor);
  font-weight: bolder;
}

.second-btn-custom-bis {
  border: 3px solid var(--btn-bgColor)!important;
  background-color: var(--btn-bgColor);
  color: white;
  font-weight: bolder;
}


.fs-24p {
  font-size: 24px;
}
.black {
  color: black;
}
.mt-15p {
  margin-top: 15px;
}
.input-section {
  padding-top: 60px;
}
.block-title {
  font-family: 'TitilliumWeb-Bold';
}

.p-l-0 {
  padding-left: 0;
}

.p-r-0 {
  padding-right: 0;
}

.m-l-0 {
  margin-left: 0!important;
}

.m-l-r-0 {
  margin-left: 0!important;
  margin-right: 0!important;
}

.no_hidden_over_flow {
  overflow-x: unset!important;
}
.page-title {
  font-family: 'TitilliumWeb-Bold';
  text-align: center;
  width: 100%!important;
  margin-top: 50px;
  text-transform: uppercase;
}

.title_el {
  margin-top: 5px;
  color: var(--btn-bgColor);
}

.sub_title-el {
  color: dodgerblue;
}

.ml-67px {
  margin-left: 67px;
}

.ml-55px {
  margin-left: 55px;
}

.ml-60px {
  margin-left: 60px;
}

.custom-form-group-title {
  width: 82%;
  display: inline-block;
  font-weight: bolder;
}

.custom-form-group-illustration-icon {
  height: 40px;
  margin-right: 12px;
  width: 9%;
  display: inline-block;
  margin-top: -50px;
}
.custom-form-group-title-block {
  /*width: 75%;*/
  /*display: inline-block;*/
}

.custom-form-group-title-block {

}

.custom-title {
  font-size: 23px;
  font-weight: bold;
  display: block;
}

.custom-sub-title {
  font-size: 16px;
  font-weight: bold;
}

.custom-form-control {
  margin-left: 67px;
}

.h-40p {
  height: 40px!important;
}
.h-37p {
  height: 37px!important;
}

.seprator-dashed {
  border: 1px dashed darkgray;
  margin-left: 67px;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 50px;
}

.seprator-dashed-without-margin {
  border: 1px dashed black;
  width: 100%;
  margin-top: 33px;
}

.border-radius-6p {
  border-radius: 6px;
}
.ft-size-12p {
  font-size: 12px!important;
}

.width-160p {
  min-width: 160px;
}
.pricing-tva {
  padding: 6px 30px;
  margin-left: 18px;
  margin-right: 11px;
}

.pricing-no-tva {
  padding: 6px 30px;
  margin-right: 11px;
}
.width-110 {
  width: 110%;
}


.save-btn {
  background-color: var(--btn-bgColor);
  padding: 5px 21px;
  color: white;
  text-transform: uppercase;
}


.cancel-btn {
  background-color: lightgray;
  padding: 5px 21px;
  color: white;
  text-transform: uppercase;
  margin-right: 15px;
}
.select-padding {
 padding: 6px 13px!important;
}
.w-123 {
  width: 123%!important;
}
.color-blue {
  color: cornflowerblue;
}
.custom-form--one-label-group-illustration-icon {
  height: 40px;
  margin-right: 12px;
  width: 9%;
  display: inline-block;
}

.custom-form-margin-left {
  margin-left: 67px;
  margin-top: 5px;
}
.display-none {
  display: none;
}
.padding-left-unset {
  padding-left: unset!important;
}
.margin-b-unset {
  margin-bottom: unset!important;
}
.btn-lg-text-padding {
  padding: 6px 15px;
}

.btn-color {
  color: var(--btn-bgColor);
}

.acitvation-bloc {
  background-color: azure;
  width: 144%;
  padding: 20px;
}
.cursor-pointer {
  cursor: pointer;
}

.width-150 {
  width: 150%;
}

.activate-payment-btn {
  width: 280px;
  height: 35px;
  color: white;
  background-color: var(--btn-bgColor);
}
.bg-azure {
  background-color: azure !important;
}

.activate-btn-box {
  padding: 26px 30px;
}
.fs-16 {
  font-size: 16px;
}
.fs-15 {
  font-size: 15px;
}

.disbaled-custom {
  background-color: #fff!important;
  border:3px solid #e5e5e5!important;
  color: #e5e5e5!important;
  cursor: not-allowed!important;
}
.width-100p {
  width: 100px;
}
.disabled_color {
  color: darkgray;
}
